import { api } from '../api';

type ResetResponse = string;

interface ResetRequest {
  newPassword: string;
  token: string;
}

const requestReset = async ({ newPassword, token }: ResetRequest) =>
  api.post<ResetResponse>('/password.reset', { new: newPassword, token });

export default requestReset;
