import checkStatus from './checkStatus';
import createFetchOptions from './createFetchOptions';

const post =
  (domain: string) =>
  async <T>(endpoint: string, data = {}, headers = {}): Promise<T> => {
    const response = await fetch(`${domain}${endpoint}`, createFetchOptions(data, headers));
    return checkStatus<T>(response);
  };

export const api = {
  post: post(__CONFIG__.apiUrl),
};

export const loginApi = {
  post: post(__CONFIG__.loginApiUrl),
};
